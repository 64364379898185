export default [
  {
    title: 'หน้าหลัก',
    route: 'home',
    icon: 'HomeIcon',
    titlesToCheck: 'หน้าหลัก',
  },

  //announce
  {
    title: 'ประกาศชุมชน',
    route: 'announceinfor',
    icon: 'BellIcon',
    titlesToCheck: 'ประกาศชุมชน',
  },
  {
    title: 'ข้อมูลธุรกิจ',
    route: 'businessinfor',
    icon: 'BriefcaseIcon',
    titlesToCheck: 'ข้อมูลธุรกิจ',
  },
  {
    title: 'ข้อมูลบริการ',
    route: 'serviceinfor',
    icon: 'BookOpenIcon',
    titlesToCheck: 'ข้อมูลบริการ',
  },
  {
    title: 'ข้อมูลท่องเที่ยว',
    route: 'travelinfor',
    icon: 'MapIcon',
    titlesToCheck: 'ข้อมูลท่องเที่ยว',
  },

  //ticket
  {
    title: 'แจ้งเหตุ',
    route: 'inform',
    icon: 'AlertTriangleIcon',
    titlesToCheck: 'ฉุกฉิน S.O.S',
  },
  {
    title: 'แจ้งซ่อมบำรุง',
    route: 'maintenance',
    icon: 'ToolIcon',
    titlesToCheck: 'แจ้งซ่อมบำรุง',
  },
  {
    title: 'ร้องเรียน',
    route: 'appeal',
    icon: 'ArchiveIcon',
    titlesToCheck: 'ร้องเรียน',
  },

  //map
  // {
  //   title: 'เเจ้งเหตุ',
  //   route: 'reportofincident',
  //   icon: 'AlertTriangleIcon',
  // },
  // {
  //   title: 'ภาพเเผ่นที่',
  //   route: 'reportofincident',
  //   icon: 'MapIcon',
  // },

  {
    title: 'ประวัติการเข้าพบ',
    route: 'historyMeet',
    titlesToCheck: 'ผู้มาติดต่อ/นัดหมาย',
    icon: 'CoffeeIcon',
    // children: [
    //   {
    //     title: 'สร้างนัดหมาย',
    //     route: 'addMeet',
    //   },
    //   {
    //     titlesToCheck: 'ผู้มาติดต่อ/นัดหมาย',
    //     title: 'ประวัติการเข้าพบ',
    //     route: 'historyMeet',
    //   },
    // ],
  },
  // {
  //   title: 'ลงทะเบียนผู้ใช้งาน',
  //   route: 'user_regis',
  //   icon: 'UserPlusIcon',
  // },
];

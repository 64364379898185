<template>
  <li
    v-if="canViewVerticalNavMenuLink(item) && titlesToCheck.length !== 0"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link v-if="item.titlesToCheck === 'หน้าหลัก' || titlesToCheck[0].titlesToCheck === item.titlesToCheck" v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
  import { useUtils as useAclUtils } from '@core/libs/acl';
  import { BLink, BBadge } from 'bootstrap-vue';
  import { useUtils as useI18nUtils } from '@core/libs/i18n';
  import useVerticalNavMenuLink from './useVerticalNavMenuLink';
  import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';
  import axios from 'axios';
  import API from '@/connect/config';

  export default {
    components: {
      BLink,
      BBadge,
    },
    data() {
      return {
        titlesToCheck: [],
       
      };
    },
    mixins: [mixinVerticalNavMenuLink],
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    async created() {
      const jwt = JSON.parse(localStorage.getItem('company_jwt_vis'));
      const headers = {
        Authorization: localStorage.getItem('refresh_token'),
      };
      const res = await axios.get(`${API}features/${jwt.userId}`, headers);
      // console.log(this.$props.item);
      const matchedTitles = [this.$props.item].filter((item) => res.data.message.result[0].featuresData.includes(item.titlesToCheck));

      this.titlesToCheck = matchedTitles;
      this.titlesToCheck.push({
        title: 'หน้าหลัก',
        route: 'home',
        icon: 'HomeIcon',
        titlesToCheck: 'หน้าหลัก',
      });
      // console.log(this.$props.item);
    },
    setup(props) {
      const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item);
      const { t } = useI18nUtils();
      const { canViewVerticalNavMenuLink } = useAclUtils();

      return {
        isActive,
        linkProps,
        updateIsActive,

        // ACL
        canViewVerticalNavMenuLink,

        // i18n
        t,
      };
    },
  };
</script>
